<template>
  <ModalWrapper @close="closeAction">
    <!-- Head -->
    <template #head>
      <div class="flex ai-c ggap-10">
        <h1 class="title">{{ currentId ? 'Редактирование сообщества' : 'Новое сообщество' }}</h1>
      </div>
    </template>

    <!-- Body -->
    <template #default>
      <div class="form-add pos-r z0 p-20 grid ggap-20" :class="[isMobile ? 'gtc-1' : 'gtc-auto-1']" id="form-add">
        <BaseLoad v-if="isLoad" class="grid jc-c z99999 bg white" />
        <!-- Avatar -->
        <div class="flex" :class="[isMobile ? 'jc-c' : 'jc-fs']">
          <AvatarVue v-model="formData.image" />
        </div>

        <!-- Text & Tags -->
        <div class="grid ggap-30">
          <FormInput
            type="text"
            label="Название сообщества"
            placeholder="Название должно отражать деятельность сообщества"
            required
            :minlength="5"
            :maxlength="100"
            v-model="formData.title"
          />

          <FormTags
            type="text"
            label="Теги"
            required
            tagsName="communityTags"
            dispatcher="GET_COMMUNITY_TAGS"
            v-model="formData.tags"
            :canAdd="isAdmin"
          />

          <FormEditor
            label="Описание сообщества"
            placeholder="Опишите коротко и по существу..."
            class="big"
            required
            :minlength="5"
            :maxlength="200"
            v-model="formData.description"
          />
        </div>
      </div>
    </template>

    <!-- Foot -->
    <template #foot>
      <div class="flex ai-c ggap-20" :class="{ 'form-add__disabled': isLoad }">
        <div class="flex ai-c ggap-10 ml-auto">
          <button class="btn transparent-grey" @click.prevent="closeAction">Отмена</button>
          <button class="btn primary" @click.prevent="submitAction">
            {{ currentId ? 'Сохранить изменения' : 'Опубликовать' }}
          </button>
        </div>
      </div>
    </template>
  </ModalWrapper>
</template>

<script setup>
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { useStore } from 'vuex'
import { defineEmits, defineProps, reactive, ref, toRefs, computed } from 'vue'
import { BaseLoad, ModalWrapper, FormInput, FormTags, FormEditor } from '@/components'
import AvatarVue from './Avatar.vue'
import { isInvalidForm, $busEmit } from '@/plugins'
import { communities } from '@/api'

// Emits
const emits = defineEmits(['close'])

// Props
const props = defineProps({
  currentId: {
    type: [Number, String],
    default: 0
  }
})

// Data
const profile = computed(() => store.getters.profile)
const isAdmin = computed(() => profile.value?.negotiation_role === 'Admin')
const isMobile = computed(() => store.getters.isMobile)
momentDurationFormatSetup(moment)
const { currentId } = toRefs(props)
const store = useStore()
const isLoad = ref(false)
const formData = reactive({
  title: '',
  description: '',
  tags: [],
  image: {}
})

// Created
if (currentId.value) getPost()

// Methods
function getPost() {
  isLoad.value = true
  communities
    .get(currentId.value)
    .then(({ data }) => {
      formData.title = data?.data?.title
      formData.description = data?.data?.description
      formData.users = data?.data?.users
      formData.author = data?.data?.author
      formData.image = {
        file: null,
        url: data?.data?.image
      }
      formData.tags = data?.data?.tags || []
    })
    .finally(() => (isLoad.value = false))
}

function closeAction() {
  emits('close')
  formData.title = ''
  formData.description = ''
  formData.image = {}
  formData.tags = []
}
async function submitAction() {
  if (isInvalidForm('form-add')) return
  isLoad.value = true
  const payload = {
    ...formData,
    description: formData.description.split('<p>&nbsp;</p>').join('')
  }
  if (currentId.value) {
    payload.id = currentId.value
    payload.action = 'update'
  }

  try {
    const { data } = await store.dispatch('communities/POST', payload)
    if (currentId.value) {
      delete payload.action
      const updateData = {
        ...payload,
        image: payload?.image?.url
      }
      store.commit('communities/CHANGE_ITEM', { id: currentId.value, payload: updateData })
    } else {
      store.commit('communities/PUSH_ITEM', data.data)
    }
    const link = currentId.value ? `/communities/single/${currentId.value}` : `/communities/single/${data.data.id}`
    const message = currentId.value
      ? `Сообщество <a href="${link}">${payload.title}</a> успешно изменено!`
      : `Новое сообщество <a href="${link}">${data.data.title}</a> успешно создано!`

    $busEmit('setToast', {
      type: 'green',
      icon: 'check-circle',
      message: message
    })
    $busEmit('updateItem')

    closeAction()
  } catch (error) {
    console.log(error)
  } finally {
    isLoad.value = false
  }
}
</script>

<style lang="scss" scoped>
.form-add {
  width: 700px;

  &__tags {
    background-color: var(--grey-l);

    &-select {
      flex: auto;

      &-input {
        border: 0;
        background-color: transparent;
        height: 30px;
      }

      &-ddown {
        box-shadow: var(--box-shadow);
      }
    }
  }

  &__disabled {
    pointer-events: none;
    filter: grayscale(1);
  }
}

@media screen and (max-width: 800px) {
  .form-add {
    width: calc(100vw - 40px);
  }
}
</style>
