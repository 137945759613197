<template>
  <div class="form-avatar cover ava-150 pos-r">
    <input
      type="file"
      name="file"
      id="file"
      class="form-avatar__input"
      @change="setFiles($event.target.files)"
      accept=".svg,.jpg,.jpeg,.png,.gif"
    />
    <label for="file" class="form-avatar__label pos-a z1 flex fd-c ai-c jc-c ggap-5">
      <BaseIcon class="ic-30 black" icon="plus-circle" />
      <b class="fs-16">выбрать</b>
    </label>
    <img :src="propValue && propValue.url ? propValue.url : require('@/assets/img/no-photo.jpg')" />
  </div>
</template>

<script setup>
import { defineEmits, defineProps, toRefs, computed } from 'vue'
import { BaseIcon } from '@/components'

// Emits
const emits = defineEmits(['update:modelValue'])

// Props
const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({})
  }
})

// Data
const { modelValue } = toRefs(props)

// Computed
const propValue = computed({
  get: () => modelValue.value,
  set: (val) => emits('update:modelValue', val)
})

// Methods
function setFiles(files) {
  const item = files[0]
  propValue.value = {
    file: item,
    url: URL.createObjectURL(item)
  }

  const inputFile = document.getElementById('file')
  inputFile.value = ''
}
</script>

<style lang="scss" scoped>
.form-avatar {
  &__input {
    display: none;
  }
  &__label {
    opacity: 0;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: var(--rgba-05);
    backdrop-filter: blur(5px);
  }

  &:hover &__label {
    opacity: 1;
  }
}
</style>
